import { getCommonParamsItemLabel } from '@/utils/common.js'
export const contentTableConfig = {
  propList: [
    {
      prop: 'userName',
      label: 'customer.customer-name',
      width: 160
    },
    {
      prop: 'name',
      label: 'user.name',
      width: 250
    },
    {
      prop: 'tenantName',
      label: 'file.tenant'
    },
    {
      prop: 'email',
      label: 'user.email',
      width: 200
    },
    {
      prop: 'mobile',
      label: 'user.phone',
      width: 150
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    }
  ],
  showIndexColumn: true
}
